import React from "react";
import { FormattedMessage, FormattedHTMLMessage } from "gatsby-plugin-intl";

import AppLayout from "../../components/shared/AppLayout";
import chevronArrow from "../../assets/images/dropdown.svg";

const PageFrequentlyAskedQuestions = () => {

    const toggleAccordion = event => {
        const currentTrigger = event.currentTarget;
        const item = currentTrigger.parentNode;
        item.classList.toggle("is-active");
    };

    return (
        <AppLayout hasHeader={true} hasHeaderLogo={true} isBackBtn={true} hasFooter={true}>
            <div className="app-page page-frequently-asked-questions">

                <div className="frequently-asked-questions">
                    <div className="container">

                        <h2 className="frequently-asked-questions__title text-bold">
                            <FormattedMessage id="faq.title" />
                        </h2>

                        <ul className="frequently-asked-questions__list">
                            <li className="frequently-asked-questions__item">
                                <button className="frequently-asked-questions__toggle" type="button" onClick={toggleAccordion}>
                                    <div className="frequently-asked-questions__question">
                                        <p className="frequently-asked-questions__question-content">
                                            <FormattedMessage id="faq.listItems.0.question" />
                                        </p>
                                        <img className="frequently-asked-questions__question-icon" alt="chevron-arrow-icon" src={chevronArrow} />
                                    </div>
                                </button>

                                <div className="frequently-asked-questions__answer">
                                    <div className="frequently-asked-questions__answer-content">
                                        <FormattedHTMLMessage id="faq.listItems.0.answer" />
                                    </div>
                                </div>
                            </li>

                            <li className="frequently-asked-questions__item">
                                <button className="frequently-asked-questions__toggle" type="button" onClick={toggleAccordion}>
                                    <div className="frequently-asked-questions__question">
                                        <p className="frequently-asked-questions__question-content">
                                            <FormattedMessage id="faq.listItems.1.question" />
                                        </p>
                                        <img className="frequently-asked-questions__question-icon" alt="chevron-arrow-icon" src={chevronArrow} />
                                    </div>
                                </button>

                                <div className="frequently-asked-questions__answer">
                                    <div className="frequently-asked-questions__answer-content">
                                        <FormattedHTMLMessage id="faq.listItems.1.answer" />
                                    </div>
                                </div>
                            </li>

                            <li className="frequently-asked-questions__item">
                                <button className="frequently-asked-questions__toggle" type="button" onClick={toggleAccordion}>
                                    <div className="frequently-asked-questions__question">
                                        <p className="frequently-asked-questions__question-content">
                                            <FormattedMessage id="faq.listItems.2.question" />
                                        </p>
                                        <img className="frequently-asked-questions__question-icon" alt="chevron-arrow-icon" src={chevronArrow} />
                                    </div>
                                </button>

                                <div className="frequently-asked-questions__answer">
                                    <div className="frequently-asked-questions__answer-content">
                                        <FormattedHTMLMessage id="faq.listItems.2.answer" />
                                    </div>
                                </div>
                            </li>

                            <li className="frequently-asked-questions__item">
                                <button className="frequently-asked-questions__toggle" type="button" onClick={toggleAccordion}>
                                    <div className="frequently-asked-questions__question">
                                        <p className="frequently-asked-questions__question-content">
                                            <FormattedMessage id="faq.listItems.3.question" />
                                        </p>
                                        <img className="frequently-asked-questions__question-icon" alt="chevron-arrow-icon" src={chevronArrow} />
                                    </div>
                                </button>

                                <div className="frequently-asked-questions__answer">
                                    <div className="frequently-asked-questions__answer-content">
                                        <FormattedHTMLMessage id="faq.listItems.3.answer" />
                                    </div>
                                </div>
                            </li>

                            <li className="frequently-asked-questions__item">
                                <button className="frequently-asked-questions__toggle" type="button" onClick={toggleAccordion}>
                                    <div className="frequently-asked-questions__question">
                                        <p className="frequently-asked-questions__question-content">
                                            <FormattedMessage id="faq.listItems.4.question" />
                                        </p>
                                        <img className="frequently-asked-questions__question-icon" alt="chevron-arrow-icon" src={chevronArrow} />
                                    </div>
                                </button>

                                <div className="frequently-asked-questions__answer">
                                    <div className="frequently-asked-questions__answer-content">
                                        <FormattedHTMLMessage id="faq.listItems.4.answer" />
                                    </div>
                                </div>
                            </li>

                            <li className="frequently-asked-questions__item">
                                <button className="frequently-asked-questions__toggle" type="button" onClick={toggleAccordion}>
                                    <div className="frequently-asked-questions__question">
                                        <p className="frequently-asked-questions__question-content">
                                            <FormattedMessage id="faq.listItems.5.question" />
                                        </p>
                                        <img className="frequently-asked-questions__question-icon" alt="chevron-arrow-icon" src={chevronArrow} />
                                    </div>
                                </button>

                                <div className="frequently-asked-questions__answer">
                                    <div className="frequently-asked-questions__answer-content">
                                        <FormattedHTMLMessage id="faq.listItems.5.answer" />
                                    </div>
                                </div>
                            </li>

                            <li className="frequently-asked-questions__item">
                                <button className="frequently-asked-questions__toggle" type="button" onClick={toggleAccordion}>
                                    <div className="frequently-asked-questions__question">
                                        <p className="frequently-asked-questions__question-content">
                                            <FormattedMessage id="faq.listItems.6.question" />
                                        </p>
                                        <img className="frequently-asked-questions__question-icon" alt="chevron-arrow-icon" src={chevronArrow} />
                                    </div>
                                </button>

                                <div className="frequently-asked-questions__answer">
                                    <div className="frequently-asked-questions__answer-content">
                                        <FormattedHTMLMessage id="faq.listItems.6.answer" />
                                    </div>
                                </div>
                            </li>

                        </ul>

                    </div>
                </div>

            </div>
        </AppLayout>
    );
};

export default PageFrequentlyAskedQuestions;
